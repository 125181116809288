.autopayment-type {
  position: relative;
  display: block;

  &--sberbank {
    @include media(lg) {
      display: none;
    }
  }
}

// Костыль, пока тестируются две версии пояснения рассрочки
label.autopayment-type {
  cursor: pointer;
}

.autopayment-type__content {
  display: grid;
  grid-gap: 2px 12px;
  grid-template-columns: 16px 1fr;
  align-items: flex-start;
  height: 100%;
  padding: 16px;
  border: 1px solid var(--stroke-main-color);
  border-radius: 10px;
  transition: border-color $duration-hover-button;

  &::before {
    display: block;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border: 1px solid var(--stroke-accent-color);
    border-radius: 50%;
    transition: background-color $duration-hover-button,
      border $duration-hover-button;
    content: '';
  }

  &--tooltip {
    padding-bottom: 56px;

    @include media(lg) {
      padding-bottom: 62px;
    }

    @include media(lg) {
      padding-bottom: 64px;
    }
  }
}

.autopayment-type__label {
  display: block;
  cursor: pointer;

  @include media(xl) {
    height: 100%;
  }
}

.autopayment-type__title {
  @include media(sm-max) {
    max-width: 234px;
  }
}

.autopayment-type__info {
  display: flex;
  grid-column: 2/3;
  align-items: center;
}

.autopayment-type__logo {
  height: 20px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.autopayment-type__input:checked ~ .autopayment-type__content::before {
  border-color: var(--accent-brand-color);
  border-width: 6px;
  background-color: var(--accent-always-white-color);
}

@include hover('.autopayment-type') {
  .autopayment-type__content {
    border-color: var(--accent-brand-color);
  }
}

.autopayment-type__input:focus-visible ~ .autopayment-type__content {
  border-color: var(--accent-brand-color);
}

.is-safari .autopayment-type__input:focus ~ .autopayment-type__content {
  border-color: var(--accent-brand-color);
}

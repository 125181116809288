.portfolio-button:focus-visible {
  color: var(--text-main-color);
}

/* stylelint-disable */
.portfolio-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px 8px 8px;
  border-radius: 12px;
  color: var(--text-secondary-color);
  transition: all 70ms ease-in-out;

  /* themes */
  &--white {
    background-color: var(--bg-main-color);
  }

  &--grey {
    background-color: var(--bg-secondary-color);
  }
  /* end themes */

  @include hover {
    color: var(--text-main-color);
  }
}

.is-safari .portfolio-button:focus {
  color: var(--text-main-color);
}
/* stylelint-enable */

.portfolio-button__logo {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 8px;
  object-fit: cover;
  overflow: hidden;
}

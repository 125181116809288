
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import 'mixins';

// TODO придумать куда их засунуть т.к. сборка через webpack тянет этот код в каждый css файл
[data-theme='light'] {
  --stroke-custom-color: var(--stroke-accent-color);
}

[data-theme='dark'] {
  --stroke-custom-color: var(--bg-accent-color);
}

@import 'ui';
@import 'fonts';
@import 'scaffolding';

@import 'start-blocks';

.details-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  background-color: var(--bg-accent-color);
}

.details-modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 92px;
  overflow: hidden;

  @include media(sm-max) {
    padding-right: 0;
    padding-left: 0;
  }

  @include media(lg) {
    padding-top: 88px;
    padding-bottom: 104px;
  }
}

.details-modal__count {
  position: absolute;
  top: 30px;
  left: 20px;
  color: var(--accent-always-white-color);

  @include media(lg) {
    top: 34px;
    left: 24px;
  }
}

.details-modal__slider {
  width: 100%;
  height: 100%;
}

.details-modal__list {
  @include ulres;

  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.details-modal__item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.details-modal__play {
  @include inpres;
  @include z-index(absolute, base, overlay);

  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  color: rgba(var(--accent-always-black-color-rgb), .5);
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 300ms ease-out;

  @include hover {
    color: var(--accent-always-black-color);
    transform: translate(-50%, -50%) scale(1.1);
  }

  @include media(lg) {
    width: 72px;
    height: 72px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.details-modal__play:focus-visible {
  color: var(--accent-always-black-color);
  transform: translate(-50%, -50%) scale(1.1);
}

.details-modal__picture {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--zoom {
    cursor: zoom-in;
  }

  &--zoomed {
    cursor: zoom-out;
  }

  &--dragging {
    cursor: grabbing;
  }
}

.details-modal__viewport {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 1px;
  max-height: none;
  margin: auto;
}

.details-modal__content {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate3d(0, 0, 0) scale(1);
  transform-origin: 0 0;
  transition: none;
  user-select: none;
  touch-action: none;
}

.details-modal__desc {
  margin-top: 20px;
  margin-bottom: 0;
  color: var(--text-main-color);
  text-align: center;

  @include media(lg) {
    margin-top: 24px;
  }
}

.details-modal__controls {
  position: absolute;
  bottom: 8px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 424px;
  height: 64px;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--bg-with-shadow-color);
  transform: translateX(-50%);

  @include media(sm-max) {
    &:not(&--no-slider) {
      width: calc(100% - 16px);
    }
  }

  @include media(lg) {
    bottom: 16px;
  }

  &--no-slider {
    justify-content: center;
    width: 160px;
  }
}

.details-modal__button {
  position: relative;

  &::before {
    position: absolute;
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    content: '';
  }

  &:disabled {
    color: var(--text-disable-color);
    background-color: var(--bg-secondary-accent-color);
    cursor: default;
  }
}

.details-modal__zoom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
}

.details-modal__zoom-btn {
  @include inpres;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--icon-main-color);
  cursor: pointer;
  transition: all $duration-hover-button;

  &::before {
    position: absolute;
    top: -21px;
    right: -13px;
    bottom: -21px;
    left: -13px;
    content: '';
  }

  &:disabled {
    color: var(--icon-addition-color);
    cursor: default;
  }

  &:not(:disabled) {
    @include hover {
      color: var(--accent-always-white-color);
      background-color: var(--accent-always-black-color);
    }
  }

  &:not(:disabled):focus-visible {
    @include media(lg) {
      color: var(--accent-always-white-color);
      background-color: var(--accent-always-black-color);
    }
  }
}

.is-safari .details-modal__zoom-btn:not(:disabled):focus {
  @include media(lg) {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }
}

.details-modal__size {
  font-feature-settings: 'tnum';
  color: var(--accent-always-white-color);
}

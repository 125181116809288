.ui-link:focus-visible {
  text-decoration: none;
}

/* stylelint-disable */
.ui-link {
  color: inherit;
  text-decoration: underline;

  &:visited {
    color: var(--text-link-color);
  }

  @include hover {
    text-decoration: none;
  }
}

.is-safari .ui-link:focus {
  text-decoration: none;
}
/* stylelint-enable */

.ui-video-modal__video {
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 12px;
    object-fit: cover;

    @include media(lg) {
      border-radius: 20px;
    }
  }
}

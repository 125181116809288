.block {
  padding: 56px 0;


  @include media(lg) {
    padding: 120px 0;
  }

  &--special {
    background-color: var(--special-color);
  }

  &--default {
    background-color: var(--default-color);
  }
}

[data-theme='dark'] .block--special,
[data-theme='dark'] .block--default {
  background-color: var(--bg-main-color);
}

.block--special + .block--special,
.block--default + .block--default,
[data-theme='dark'] .block--special + .block--default,
[data-theme='dark'] .block--default + .block--special {
  padding-top: 8px;

  @include media(lg) {
    padding-top: 20px;
  }
}

.accordion {
  @include ulres;

  border-radius: 12px;
  overflow: hidden;

  @include media(lg) {
    border-radius: 16px;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }
}

.accordion.accordion--part {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

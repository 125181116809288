// Close
.button-close {
  @include inpres;

  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid var(--stroke-accent-color);
  border-radius: 50%;
  background-color: transparent;
  transform: rotate(45deg);
  cursor: pointer;
  transition: $transition;

  @include media(md) {
    width: 28px;
    height: 28px;
  }

  @include hover {
    color: #fff;
    background-color: #000;
  }

  &:focus-visible {
    color: #fff;
    background-color: #000;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 19px;
    height: 19px;
    transform: translate(-50%, -50%);

    @include media(md) {
      width: 21px;
      height: 21px;
    }
  }
}

.is-safari .button-close:focus {
  color: #fff;
  background-color: #000;
}

// Arrow
.button-arrow {
  @include inpres;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid var(--stroke-accent-color);
  border-radius: 50%;
  color: var(--stroke-accent-color);
  cursor: pointer;
  transition: $transition;

  @include media(lg) {
    width: 26px;
    height: 26px;
  }

  &:disabled,
  &--disabled {
    border-color: var(--text-disable-color);
    color: var(--text-disable-color);
    background-color: transparent;
    cursor: default;
  }

  &:not(:disabled):not(&--disabled) {
    @include hover {
      color: #fff;
      background-color: #000;
    }
  }

  &:not(:disabled):not(&--disabled):focus-visible {
    color: #fff;
    background-color: #000;
  }

  &--prev {
    transform: rotate(180deg);
  }

  &.swiper-button-disabled {
    border-color: var(--text-disable-color);
    color: var(--text-disable-color);
    cursor: default;
  }
}

.is-safari .button-arrow:not(:disabled):not(.button-arrow--disabled):focus {
  color: #fff;
  background-color: #000;
}

// Popup close
.popup-close {
  @include inpres;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--text-main-color);
  background-color: var(--bg-secondary-accent-color);
  cursor: pointer;
  transition: $transition;

  @include hover {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }

  &:focus-visible {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }
}

.is-safari .popup-close:focus {
  color: var(--accent-always-white-color);
  background-color: var(--accent-always-black-color);
}

// Vk
.button-vk {
  display: grid;
  grid-gap: 3px 12px;
  grid-column: 1/-1;
  grid-template-columns: 20px 1fr;
  align-items: center;
  width: 100%;
  min-height: 56px;
  margin-bottom: 16px;
  padding: 10px 11px;
  border: 1px solid var(--stroke-main-color);
  border-radius: 10px;
  color: var(--text-main-color);
  text-align: center;
  background-color: var(--bg-with-shadow-color);
  cursor: pointer;
  transition: border-color $transition-input;

  @include media(md) {
    grid-template-columns: 20px 1fr 30px;
    padding: 10px 15px;
  }

  &:focus-visible {
    border-color: var(--stroke-secondary-color);
  }

  &:not(:disabled) {
    @include hover {
      border-color: var(--stroke-secondary-color);
    }
  }
}

.is-safari .button-vk:focus {
  border-color: var(--stroke-secondary-color);
}

.button-vk__icon {
  grid-row: 1/3;
  align-self: center;
}

.button-vk__text {
  overflow: hidden;
  letter-spacing: -.01em;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include media(sm-max) {
    max-width: 90%;
  }

  &--center {
    grid-row: 1/3;
  }
}

.button-vk__tel {
  grid-row: 2/3;
  grid-column: 2/3;
  color: var(--text-secondary-color);
  letter-spacing: .005em;
}

.button-vk__avatar {
  grid-row: 1/3;
  grid-column: 3/4;
  border-radius: 50%;
  object-fit: cover;

  @include media(sm-max) {
    display: none;
  }
}

.social {
  @include ulres;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.social__item {
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.social__link {
  display: block;
  height: 24px;
  color: var(--icon-main-color);
  transition: $transition;

  @include hover {
    color: var(--icon-addition-color);
  }

  &:focus-visible {
    color: var(--icon-addition-color);
  }
}

.is-safari .social__link:focus {
  color: var(--icon-addition-color);
}

.social__icon {
  width: 24px;
  height: 24px;
}

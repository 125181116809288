.form {
  display: flex;
  flex-direction: column;
}

.form__input {
  margin-bottom: 16px;

  &--hidden {
    height: 0;
    margin: 0;
    overflow: hidden;
    transition: all 300ms linear;
    will-change: height, margin;
  }

  &--hidden.form__input--show {
    height: 56px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--show.ui-field--error {
    height: 78px;
  }
}

.form__button {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;

  &:first-child {
    margin-top: 0;
  }
}

// removing autocomplete highlight color in chrome
.ui-field__input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

.ui-field__input:-webkit-autofill:focus-visible {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

.form__checkbox {
  display: block;
  margin-top: 4px;
  margin-bottom: 4px;
  color: var(--text-main-color);
  cursor: pointer;
  user-select: none;
}

.form__checkbox-text {
  position: relative;
  display: block;
  padding-left: 32px;

  @include media(md) {
    padding-left: 28px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid var(--stroke-accent-color);
    border-radius: 3px;
    background-color: transparent;
    transform: translateY(-50%);
    transition: $transition;
    content: '';

    @include media(md) {
      width: 16px;
      height: 16px;
    }
  }

  @include hover {
    &::before {
      border-color: var(--accent-brand-color);
    }
  }
}

.form__checkbox-icon {
  position: absolute;
  top: 50%;
  left: 4px;
  display: none;
  transform: translateY(-50%);

  @include media(sm-max) {
    width: 12px;
    height: 7px;
  }

  @include media(md) {
    left: 3px;
  }
}

.form__checkbox-input:focus-visible ~ .form__checkbox-text {
  &::before {
    border-color: var(--accent-brand-color);
    border-width: 2px;
  }
}

.form__checkbox-input:checked {
  & ~ .form__checkbox-text {
    &::before {
      border-color: var(--accent-brand-color);
      background-color: var(--accent-brand-color);
    }

    .form__checkbox-icon {
      display: block;
      color: var(--accent-always-white-color);
    }
  }

  &:focus-visible ~ .form__checkbox-text {
    &::before {
      border-width: 2px;
    }
  }
}

.form__policy {
  margin-bottom: 0;
  color: var(--text-secondary-color);

  a {
    text-decoration: underline;

    @include hover {
      text-decoration: none;
    }

    &:focus-visible {
      text-decoration: none;
    }
  }
}

.form__accept .form__policy {
  color: var(--text-main-color);
}

.form__accepted {
  margin-top: 16px;

  // TODO: Удалить после после доработки компонента
  font-size: 12px;
  color: var(--text-secondary-color);

  .ui-checkbox-field__icon {
    color: var(--icon-secondary-color);
  }

  .ui-checkbox-field__input:checked ~ .ui-checkbox-field__value {
    &::before {
      border-color: var(--bg-secondary-color);
      background-color: var(--bg-secondary-color);
    }
  }
}

// Form with grid
.form--compact {
  display: flex;
  flex-direction: column;

  @include media(xl) {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .form__input--wide,
  .form__button,
  .form__policy,
  .form__accept {
    grid-column: 1/-1;
  }
}

.ui-checkbox-field .form__policy {
  grid-column: 2/3;
}

// Focus-visible-костыли для Safari
.is-safari {
  .form__policy a:focus {
    text-decoration: none;
  }

  .form__input:-webkit-autofill:focus {
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
  }

  .form__checkbox-input:focus ~ .form__checkbox-text {
    &::before {
      border-color: var(--accent-brand-color);
      border-width: 2px;
    }
  }
}

.subscribe {
  position: relative;
  padding: 32px;
  border-radius: 16px;
  background-color: var(--bg-with-shadow-color);

  @include media(sm-max) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media(md, lg-max) {
    display: grid;
  }

  @include media(md) {
    grid-column-gap: 84px;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 304px;
    align-items: flex-start;
  }

  @include media(lg) {
    grid-column-gap: 112px;
    grid-template-columns: 1fr 400px;
    padding: 48px;
    border-radius: 20px;
  }

  &--two-columns {
    @include media(xl) {
      display: grid;
      grid-column-gap: 132px;
      grid-template-columns: 1fr 520px;
    }
  }
}

.subscribe__title {
  margin-bottom: 32px;

  @include media(md) {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  @include media(md, lg-max) {
    margin-bottom: 0;
  }
}

.subscribe--two-columns .subscribe__title {
  @include media(xl) {
    margin-bottom: 0;
  }
}

.subscribe__desc {
  @include ulres;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 26px;
  font-size: 12px;

  @include media(md) {
    display: block;
    grid-row: 2/3;
    grid-column: 1/2;
  }

  @include media(lg) {
    display: flex;
    margin: 0 0 20px;
  }

  li {
    width: 50%;
    padding-right: 15px;

    @include media(md) {
      display: flex;
      width: 100%;
      margin-bottom: 8px;
    }

    @include media(lg) {
      width: 50%;
    }
  }

  b {
    display: block;
    margin-top: 8px;

    @include media(md) {
      margin: 0;
      margin-left: 8px;
    }
  }
}

.subscribe__form {
  @include media(md) {
    grid-row: 1/-1;
    grid-column: 2/3;
  }
}

.price-info {
  @include z-index(base);

  display: grid;
  grid-gap: 4px;

  @include media(xl) {
    grid-gap: 8px;
  }

  @include media(xl) {
    grid-template-columns: 1fr 570px;
  }
}

.price-info__block {
  position: relative;
  padding: 32px;
  border-radius: 12px;
  background-color: var(--bg-with-shadow-color);

  @include media(sm-max) {
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media(lg) {
    padding: 48px;
    border-radius: 20px;
  }

  &:first-child:not(&--no-banner) {
    padding-bottom: 0;

    @include media(lg) {
      display: flex;
      flex-direction: column;
    }
  }
}

.price-info__header {
  margin-bottom: 24px;

  @include media(lg) {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.price-info__data {
  @include ulres;

  @include media(md-max) {
    margin-bottom: 20px;
  }

  @include media(lg) {
    grid-row: 1/2;
    grid-column: 2/3;
    min-width: 150px;
    text-align: right;
  }
}

.price-info__data-item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.price-info__title {
  margin-bottom: 0;

  @include media(md) {
    align-self: center;
  }

  @include media(xl) {
    max-width: 400px;
  }
}

.price-info__features {
  @include ulres;

  margin-bottom: 32px;

  @include media(lg) {
    margin-bottom: 48px;
  }
}

.price-info__feature {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;

    @include media(lg) {
      margin-bottom: 12px;
    }
  }

  &:not(:first-child:last-child) {
    padding-left: 22px;

    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--text-main-color);
      content: '';
    }
  }
}

.price-info__list {
  @include ulres;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;

  @include media(lg) {
    margin-bottom: 28px;
  }

  &--credit {
    justify-content: flex-start;
    margin-bottom: 24px;

    @include media(lg) {
      margin-bottom: 32px;
    }
  }
}

.price-info__item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  &:first-child {
    margin-right: 20px;
  }

  span {
    font-feature-settings: 'tnum';
    font-variant-numeric: tabular-nums;
  }

  sup {
    margin-left: 8px;
  }

  b {
    margin-left: 8px;
  }
}

.price-info__list--credit .price-info__item {
  margin-bottom: 8px;

  @include media(lg) {
    margin-bottom: 16px;
  }

  &:first-child {
    @include media(lg) {
      margin-right: 20px;
    }
  }
}

.price-info__item:last-child:not(:first-child) {
  color: var(--text-addition-color);
}

.price-info__subprices {
  @include ulres;

  display: flex;
  flex-direction: column;
}

.price-info__subprice {
  display: block;
  text-align: right;

  &:first-child {
    color: var(--text-main-color);
  }
}

.price-info__price-old {
  text-decoration: line-through;
}

.price-info__info {
  margin-right: -16px;
  margin-left: -16px;
  border-radius: 0 0 16px 16px;

  @include media(md) {
    margin-right: -32px;
    margin-left: -32px;
  }

  @include media(lg) {
    margin-top: auto;
    margin-right: -48px;
    margin-left: -48px;
    border-radius: 0 0 20px 20px;
  }
}

.form-popup {
  @include z-index(fixed, popup);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  color: var(--text-main-color);
  background-color: var(--bg-main-color);
}

.form-popup__wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
}

.form-popup__main {
  grid-row: 1/2;

  @include media(md-max) {
    max-width: 456px;
    margin-right: auto;
    margin-left: auto;
  }

  @include media(lg) {
    display: grid;
    grid-column-gap: 80px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 400px;
  }

  @include media(xl) {
    grid-template-columns: 1fr 520px;
  }
}

.form-popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  padding-top: 17px;
  padding-bottom: 17px;

  @include media(md) {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  @include media(lg) {
    margin-bottom: 56px;
  }
}

.form-popup__logo {
  display: block;
  flex-shrink: 0;
  width: 85px;

  @include media(md) {
    width: 108px;
  }
}

.form-popup__close {
  @include inpres;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--icon-main-color);
  background-color: var(--bg-secondary-accent-color);
  cursor: pointer;
  transition: all $duration-hover-button;

  @include hover {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }

  &:focus-visible {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }
}

.is-safari .form-popup__close:focus {
  color: var(--accent-always-white-color);
  background-color: var(--accent-always-black-color);
}

.form-popup__content {
  width: 100%;
}

.form-popup__survey {
  @include media(lg) {
    display: grid;
    grid-column-gap: 112px;
    grid-template-columns: 1fr 368px;
  }

  @include media(xl) {
    grid-column-gap: 132px;
  }
}

.form-popup__error {
  @include media(lg) {
    display: grid;
    grid-column-gap: 112px;
    grid-template-columns: 1fr 368px;
  }

  @include media(xl) {
    grid-column-gap: 132px;
  }
}

.form-popup__picture {
  display: block;
  width: 120px;
  height: 120px;
  margin-top: -32px;

  @include media(md-max) {
    margin-bottom: 20px;
  }

  @include media(lg) {
    grid-column: 2/-1;
    width: 368px;
    height: 368px;
    margin-top: -56px;
  }
}

.form-popup__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form-popup__info {
  width: 100%;

  @include media(lg) {
    grid-row: 1/2;
  }
}

.form-popup__title {
  @include media(md-max) {
    text-align: center;
  }
}

.form-popup__text {
  margin-bottom: 24px;

  @include media(md-max) {
    text-align: center;
  }

  @include media(lg) {
    margin-bottom: 40px;
  }
}

.form-popup__gifts {
  @include media(lg) {
    grid-row: 1/4;
    grid-column: 2/3;
  }
}

.form-popup__socials {
  @include ulres;

  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr;

  @include media(md-max) {
    margin-top: 32px;
  }

  @include media(lg) {
    grid-template-columns: 220px 209px;
  }
}

.form-popup__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--bg-main-color-rgb), .5);

  svg {
    width: 50px;
    height: 50px;
  }
}

// gifts
.alchemere-gifts {
  @include ulres;
}

.alchemere-gifts__item {
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 24px 1fr;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    grid-template-columns: 32px 1fr;
    padding: 20px;
    border-radius: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;

    @include media(lg) {
      margin-bottom: 16px;
    }
  }
}

.alchemere-gifts__icon {
  grid-row: 1/3;

  @include media(lg) {
    width: 32px;
    height: 32px;
  }
}

.alchemere-gifts__text {
  margin-bottom: 0;
}

// messengers
.alchemere-messengers {
  @include ulres;

  @include media(xl) {
    display: flex;
    flex-wrap: wrap;
  }
}

.alchemere-messengers__item {
  margin-bottom: 12px;

  @include media(xl) {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.alchemere-messengers__button {
  @include media(lg-max) {
    width: 100%;
  }
}

// alchemere-modal
.alchemere-modal {
  @include media(md) {
    width: 696px;
  }

  @include media(lg) {
    width: 928px;
  }

  @include media(xl) {
    width: 1080px;
  }
}

.alchemere-modal__wrapper {
  @include media(lg) {
    display: grid;
    grid-column-gap: 36px;
    grid-template-columns: 340px 1fr;
  }

  @include media(xl) {
    grid-column-gap: 56px;
    grid-template-columns: 360px 1fr;
  }
}

.alchemere-modal__pic {
  display: block;

  @include media(md-max) {
    max-width: 320px;
    margin: 0 auto 32px;
  }

  @include media(lg) {
    align-self: center;
    width: 368px;
    height: 406px;
    margin-left: -48px;
  }

  @include media(xl) {
    width: 408px;
    height: 450px;
    margin-left: -48px;
  }
}

.alchemere-modal__content {
  @include media(lg) {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.alchemere-modal__title {
  @include media(md-max) {
    text-align: center;
  }
}

.alchemere-modal__desc {
  margin-bottom: 24px;

  @include media(md-max) {
    text-align: center;
  }

  @include media(lg) {
    margin-bottom: 40px;
  }
}

.alchemere-modal__list {
  margin-bottom: 32px;
}

.alchemere-modal__button {
  @include media(md, md-max) {
    margin-right: auto;
    margin-left: auto;
  }

  @include media(lg) {
    margin-top: auto;
  }
}

//alchemere-referral
.form-popup__main-alchemere {
  padding-bottom: 40px;
}

.alchemere-referral {
  background: var(--bg-secondary-color);
  border-radius: 20px;
  padding: 20px;
  position: relative;
  grid-row: 2/3;
  margin-top: 80px;

  @include media(md) {
    padding: 32px;
    display: grid;
    column-gap: 32px;
    grid-template-columns: 285px 1fr;
    min-height: 238px;
  }

  @include media(lg) {
    column-gap: 56px;
    border-radius: 24px;
    grid-template-columns: 331px 1fr;
    padding: 48px;
    margin-top: 120px;
    min-height: 284px;
  }

  @include media(xl) {
    grid-template-columns: 264px 1fr;
    min-height: 234px;
  }
}

.alchemere-referral__img {
  width: 120px;
  height: 90px;
  overflow: hidden;

  @include media(360) {
    width: 160px;
    height: 120px;
  }

  @include media(sm-max) {
    margin-bottom: 24px;
  }

  @include media(md) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 314px;
    height: 238px;
    border-bottom-left-radius: 20px;
  }

  @include media(lg) {
    width: 379px;
    height: 284px;
    border-bottom-left-radius: 24px;
  }

  @include media(xl) {
    width: 312px;
    height: 234px;
  }
}

.alchemere-referral__title {
  grid-column: 2/3;
  grid-row: 1/2;
}

.alchemere-referral__text {
  grid-column: 2/3;
  grid-row: 2/3;
  color: var(--text-secondary-color);
}

.alchemere-referral__tooltip {
  display: inline-flex;
}

// TODO: удалить когда будет готова задача WEBDEV-167811
.alchemere-referral__oval {
  background: var(--bg-main-color);
  border-radius: 6px;
  padding: 0 6px;
  display: grid;
  grid-template-columns: 1fr 16px;
  column-gap: 6px;

  @include media(md) {
    padding: 0 8px;
    border-radius: 8px;
  }
}

.alchemere-referral__oval .ui-tooltip__icon {
  grid-column: 2/3;
  grid-row: 1;
}

.alchemere-referral__button {
  grid-column: 2/3;
  grid-row: 3/4;
  margin-top: 24px;
  align-self: flex-end;

  @include media(md) {
    margin-top: 32px;
  }
}

// lerna schedule modal
.lerna-schedule-modal__wrapper {
  @include media(md) {
    width: 372px;
  }

  @include media(lg) {
    width: 460px;
    margin-right: -32px;
    margin-left: -32px;
  }
}

.lerna-schedule-modal__desc {
  margin-bottom: 32px;
}

.lerna-schedule-modal__list {
  @include ulres;

  margin-bottom: 32px;
}

.lerna-schedule-modal__item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.lerna-schedule-modal__submit {
  position: relative;
}

.anketolog-survey__wrapper {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.anketolog-survey__close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;

  @include media(md) {
    top: 5px;
  }
}

.anketolog-survey__iframe {
  width: 100%;
  height: 100vh;
}

.anketolog-survey__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

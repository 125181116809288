.sale {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: auto 1fr auto;
  padding: 16px;
  border-radius: 12px;
  color: var(--text-main-color);
  background-color: var(--tooltip-bg-color);

  @include media(sm-max) {
    grid-gap: 4px 2px;
  }

  @include media(md) {
    display: flex;
    align-items: center;
  }

  @include media(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &--picture {
    @include media(lg) {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &--gb {
    background-color: var(--bg-secondary-accent-color);
  }
}

.sale[data-theme='dark'] {
  color: var(--accent-always-white-color);
}

.sale__pic {
  @include media(md-max) {
    display: none;
  }

  @include media(lg) {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
}

.sale--picture .sale__pic {
  @include media(lg) {
    width: 40px;
    height: 40px;
  }
}

.sale__text {
  display: block;
  grid-row: 1/2;
  grid-column: 1/3;

  @include media(md) {
    margin-right: 8px;
  }
}

.sale__discount {
  grid-column: 1/2;

  @include media(md) {
    margin-right: 2px;
  }
}

.sale__timer {
  grid-column: 2/-1;
  justify-self: flex-start;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;

  // Временный костыль, пока есть старые плашки (старая желтая и ЧП)
  .f {
    line-height: unset;
  }
}

.sale__tooltip {
  position: relative;
  grid-row: 1/-1;
  grid-column: 3/-1;

  @include media(sm-max) {
    margin-top: 2px;
  }

  @include media(md-max) {
    width: 16px;
    height: 16px;
  }

  @include media(md) {
    margin-left: auto;
  }
}

.sale--no-tooltip {
  grid-template-columns: auto 1fr;

  .sale__text {
    grid-column: 1/-1;
  }

  .sale__timer {
    grid-column: 2/-1;
  }
}

.tooltip-button {
  display: block;
  flex-shrink: 0;
  color: inherit;
  line-height: 1;

  &--with-text {
    svg {
      @include media(lg) {
        display: none;
      }
    }

    span {
      @include media(md-max) {
        display: none;
      }

      @include media(lg) {
        border-bottom: 1px dotted var(--text-main-color);
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);
      content: '';

      @include media(md) {
        width: 48px;
        height: 48px;
      }

      @include media(lg) {
        width: 126px;
        height: 58px;
      }
    }
  }
}

@include hover('.tooltip-button--with-text') {
  span {
    border-bottom-color: transparent;
  }
}

.tooltip-button--with-text:focus-visible {
  span {
    @include media(lg) {
      border-bottom-color: transparent;
    }
  }
}

.is-safari .tooltip-button--with-text:focus {
  span {
    @include media(lg) {
      border-bottom-color: transparent;
    }
  }
}

// Functional
@import 'node_modules/@skillbox/front-box/dist/Functional/UiScaffolding/style';
@import 'node_modules/@skillbox/front-box/dist/Functional/UiColors/style';
@import 'node_modules/@skillbox/front-box/dist/Functional/UiShadow/style';
@import 'node_modules/@skillbox/front-box/dist/Functional/UiVariables/style';

// Loaders
@import 'node_modules/@skillbox/front-box/dist/Loaders/UiLoaderAnimation/style';

// Buttons
@import 'node_modules/@skillbox/front-box/dist/Buttons/UiButton/style';
@import 'node_modules/@skillbox/front-box/dist/Buttons/UiSocialButton/style';
@import 'node_modules/@skillbox/front-box/dist/Buttons/UiIconButton/style';
@import 'node_modules/@skillbox/front-box/dist/Buttons/UiBackButton/style';
@import 'node_modules/@skillbox/front-box/dist/Buttons/UiPlayButton/style';

// Typography
@import 'node_modules/@skillbox/front-box/dist/Typography/UiTypography/style';
@import 'node_modules/@skillbox/front-box/dist/Typography/UiList/style';
@import 'node_modules/@skillbox/front-box/dist/Typography/UiContentWrapper/style';

// Inputs
@import 'node_modules/@skillbox/front-box/dist/Inputs/UiInput/style';
@import 'node_modules/@skillbox/front-box/dist/Inputs/UiCheckboxField/style';

// Blocks
@import 'node_modules/@skillbox/front-box/dist/Media/UiPicture/style';
@import 'node_modules/@skillbox/front-box/dist/Media/UiImage/style';
@import 'node_modules/@skillbox/front-box/dist/Media/UiVideo/style';
@import 'node_modules/@skillbox/front-box/dist/Cards/UiProductCard/style';
@import 'node_modules/@skillbox/front-box/dist/Blocks/UiAccordion/style';

// Modals
@import 'node_modules/@skillbox/front-box/dist/Modals/UiModal/style';
@import 'node_modules/@skillbox/front-box/dist/Modals/UiVideoModal/style';

// Interactive Elements
@import 'node_modules/@skillbox/front-box/dist/InteractiveElements/UiTooltip/style';
@import 'node_modules/@skillbox/front-box/dist/InteractiveElements/UiDottedLink/style';
@import 'node_modules/@skillbox/front-box/dist/InteractiveElements/UiSpoiler/style';
@import 'node_modules/@skillbox/front-box/dist/InteractiveElements/UiStretchedLink/style';

// VisualElements
@import 'node_modules/@skillbox/front-box/dist/VisualElements/UiOval/style';
@import 'node_modules/@skillbox/front-box/dist/VisualElements/UiTag/style';
@import 'node_modules/@skillbox/front-box/dist/VisualElements/UiAnimatedChevron/style';

// переопределяет размеры тултипа внутри тега
// TODO: добавить мозможность менять размеры для иконки в тултипе
.ui-tag .ui-tooltip.ui-tag__icon {
  width: 12px;
  height: 12px;
}

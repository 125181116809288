.message {
  display: none;

  b {
    display: block;
  }

  p {
    margin: 0;
  }
}

.message__error {
  position: absolute;
  top: -5px;
  left: 0;
  display: none;
  grid-column-gap: 12px;
  grid-template-columns: 24px 1fr 40px;
  width: 270px;
  padding: 12px 0 12px 12px;
  border-radius: 10px;
  color: var(--accent-always-white-color);
  background-color: var(--accent-danger-color);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .04), 0 4px 20px rgba(0, 0, 0, .1);
  transform: translateY(-100%);
  opacity: 0;

  @include media(375) {
    width: 280px;
  }

  &::before {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20ZM1.5 10a8.5 8.5 0 0 1 14-6.5l-12 12a8.5 8.5 0 0 1-2-5.5Zm8.5 8.5c-2 0-4-.7-5.5-2l12-12a8.5 8.5 0 0 1-6.5 14Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
  }
}

.message__close {
  @include inpres;

  position: relative;
  top: -12px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.message__success {
  @include z-index(absolute, base, raised);

  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 12px;
  text-align: center;
  background-color: var(--bg-with-shadow-color);

  @include media(lg) {
    border-radius: 20px;
  }

  b {
    max-width: 400px;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;

    @include media(lg) {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  p {
    max-width: 400px;
    font-size: 14px;
    line-height: 22px;
  }
}

.message__icon {
  display: block;
  width: 24px;
  height: 24px;
  margin-bottom: 16px;
}

.message__button {
  min-width: 280px;
  margin-top: 32px;

  @include media(lg) {
    min-width: 320px;
  }
}

.message__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;

  @include media(md) {
    top: 32px;
    right: 32px;
  }
}

.js-error {
  .message__error {
    display: grid;
    animation: show-message 400ms ease-in-out forwards;
  }
}

.js-success {
  .message__success {
    display: flex;
  }
}

@keyframes show-message {
  0% {
    top: -5px;
    opacity: 0;
  }

  100% {
    top: -8px;
    opacity: 1;
  }
}

.controls {
  display: flex;
  align-items: center;
}

.controls__count {
  width: 61px;

  @include media(lg) {
    margin: 0 5px;
    text-align: center;
  }
}

.controls__button {
  @include media(md-max) {
    display: none;
  }
}

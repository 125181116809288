.features {
  @include ulres;

  @include media(md) {
    display: grid;
    grid-gap: 25px 20px;
    grid-template-columns: 1fr 1fr;
  }

  @include media(lg) {
    grid-column-gap: 35px;
  }

  @include media(xl) {
    grid-column-gap: 60px;
  }
}

.features__item {
  position: relative;
  padding-left: 22px;
  font-weight: 500;

  font-size: 13px;
  line-height: 16px;

  @include media(md) {
    padding-right: 95px;
    font-size: 16px;
    line-height: 24px;
  }

  @include media(lg) {
    padding-right: 30px;
    font-size: 13px;
    line-height: 16px;
  }

  @include media(xl) {
    font-size: 12px;
  }

  &::before {
    position: absolute;
    top: 5px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000;
    content: '';

    @include media(md) {
      top: 8px;
    }

    @include media(lg) {
      top: 6px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @include media(md) {
      margin-bottom: 0;
    }
  }
}

.details {
  @include ulres;

  border-top: 1px solid var(--stroke-custom-color);

  &--no-border {
    border-top: 0;
  }

  &--count {
    counter-reset: detailsCount;
  }
}

.details__item {
  border-bottom: 1px solid var(--stroke-custom-color);
}

.details__summary {
  box-sizing: border-box;
  list-style: none;
  outline: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }

  &--empty {
    cursor: default;
    pointer-events: none;
  }
}

.details__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box; //фикс для Safari
  width: 100%;
  margin: 0;
  padding: 20px 0;
  color: inherit;
  font-weight: 500;
  text-align: left;
  cursor: pointer;

  @include media(md) {
    padding: 30px 0;
  }
}

.details__inner-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box; //фикс для Safari
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.details--count .details__button {
  position: relative;
  padding-left: 31px;

  @include media(md) {
    padding-left: 33px;
  }

  &::before {
    position: absolute;
    top: 20px;
    left: 0;
    content: counter(detailsCount) '.';
    counter-increment: detailsCount;

    @include media(md) {
      top: 30px;
    }
  }
}

.details__icon {
  position: relative;
  display: block;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  padding: 2.5px;
  border: 1px solid var(--icon-main-color);
  border-radius: 50%;
  color: var(--icon-main-color);
  transform: rotate(0);
  transition: $transition;

  @include media(md) {
    width: 28px;
    height: 28px;
    padding: 3.5px;
  }
}

.details__container[open] .details__icon--outer,
.details__container--inner[open] .details__icon {
  transform: rotate(45deg);
}

@include hover('.details__summary') {
  .details__icon {
    color: var(--bg-main-color);
    background-color: var(--icon-main-color);
  }
}

.details__summary:focus-visible {
  .details__icon {
    color: var(--bg-main-color);
    background-color: var(--icon-main-color);
  }
}

.is-safari .details__summary:focus {
  .details__icon {
    color: var(--bg-main-color);
    background-color: var(--icon-main-color);
  }
}

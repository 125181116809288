.sale-old {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 16px;
  gap: 4px;
  padding: 12px 14px;
  color: var(--text-main-color);
  background-color: var(--tooltip-bg-color);

  @include media(sm-max) {
    overflow: hidden;
  }

  @include media(lg) {
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 40px 1fr 16px;
    padding: 12px 16px;
  }
}

.sale-old.price-info__info {
  padding: 13px 16px;

  @include media(375) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @include media(md) {
    padding: 14px 32px;
  }

  @include media(lg) {
    padding: 22px 48px;
  }
}

.sale-old__pic {
  display: none;

  @include media(lg) {
    display: block;
    grid-row: 1/-1;
    grid-column: 1/2;
    align-self: center;
    width: 40px;
    height: 40px;
    margin-bottom: 0;
  }
}

.sale-old__text {
  grid-row: 2/-1;
  grid-column: 1/2;

  @include media(lg) {
    grid-column: 2/3;
  }

  &:nth-child(2) {
    grid-row: 1/2;
  }

  br {
    @include media(375) {
      display: none;
    }
  }
}

.sale-old__timer {
  font-feature-settings: 'tnum';
}

.sale-old__tooltip {
  grid-row: 1/-1;
  grid-column: 2/-1;
  margin-bottom: 0;

  @include media(lg) {
    grid-column: 3/-1;
    align-self: center;
  }

  @include media(xl) {
    position: relative;
  }

  .ui-tooltip__button {
    &::before {
      position: absolute;
      top: -12px;
      left: 50%;
      width: 42px;
      height: 88px;
      transform: translateX(-50%);
      content: '';

      @include media(lg) {
        top: 50%;
        width: 48px;
        height: 68px;
        transform: translate(-50%, -50%);
      }
    }
  }
}
